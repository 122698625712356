@import "src/assets/styles/variables";

.spinner-holder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.spinner {
  width: rem(120);
  height: rem(120);
  position: relative;
  animation: spinner-rotate 1.5s infinite linear;
  @keyframes spinner-rotate {
    to {
      transform: rotate(360deg);
    }
  }
  &.small {
    width: rem(80);
    height: rem(80);
  }
  .dot {
    width: 60%;
    height: 60%;
    position: absolute;
    top: 0;
    border-radius: 100%;
    background-image: linear-gradient(to bottom, #{ch(hero)}, #{c(hero)});
    animation: spinner-bounce 2s infinite ease-in-out;
    @keyframes spinner-bounce {
      0%, 100% {
        transform: scale(0.0);
      }
      50% {
        transform: scale(1.0);
      }
    }
    &.dot2 {
      top: auto;
      bottom: 0;
      animation-delay: -1s;
    }
  }
}
